import { ThemeProvider } from "styled-components";
import { Taplist } from "./components/Taplist";
import { Layout } from "./components/Layout";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Vuvuzela } from "./components/Vuvuzela";
import { theme } from "./ui-kit/theme";


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Header />
        <Taplist />
        <Footer />
      </Layout>
      <Vuvuzela />
    </ThemeProvider>
  );
}

export default App;
