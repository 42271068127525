import styled from "styled-components";
import { Instagram } from "../Instagram";

export const Footer = () => {
  return (
    <Wrapper>
      <Instagram />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 4rem 1rem;
  background: ${(props) => props.theme.colors.light};

  @media (min-width: 821px) {
    display: none;
  }
`;
