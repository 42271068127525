export const theme = {
  colors: {
    main: "#0e2a4d",
    highlight: "#ff7f00",
    light: "#fcf3ed",
    auxiliary: "#c48656",
  },
  fontSize: {
    growler: "5rem",
    pint: "3rem",
    halfPint: "2rem",
    shot: "1.6rem",
  },
  fontWeight: {
    semibold: 600,
    extrabold: 800,
  },
};
