const POSSIBLE_ANIMATIONS = [
  "animate__bounceIn",
  "animate__bounceInLeft",
  "animate__backInDown",
  "animate__backInLeft",
  "animate__heartBeat",
  "animate__wobble",
  "animate__tada",
  "animate__swing",
  "animate__headShake",
  "animate__shakeY",
  "animate__rubberBand",
  "animate__pulse",
  "animate__flash",
  "animate__bounce",
];

export const animationRange = ["3s", "5s", "10s", "15s", "20s", "25s", "30s"];

export const getRandomAnimation = () => {
  const randomIndex = Math.floor(Math.random() * POSSIBLE_ANIMATIONS.length);
  const nextAnimation = POSSIBLE_ANIMATIONS[randomIndex];

  return nextAnimation;
};

export const DEFAULT_ANIMATION = "animate__fadeIn";
