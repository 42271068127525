import { useEffect, useState } from "react";
import styled from "styled-components";
import ms from "ms";
import logo from "./logo.png";
import { animationRange, getRandomAnimation, DEFAULT_ANIMATION } from "./utils";
import { Instagram } from "../Instagram";
import { AppVersion } from "../AppVersion";

export const Header = () => {
  const [animation, setAnimation] = useState(DEFAULT_ANIMATION);

  useEffect(() => {
    let delay = ms("10s");
    const intervalId = setInterval(() => {
      setAnimation(getRandomAnimation());
      delay = ms(
        animationRange[Math.floor(Math.random() * animationRange.length)]
      );
    }, delay);

    return () => clearInterval(intervalId);
  }, [animation]);

  return (
    <Wrapper>
      <LogoContainer>
        <Logo
          className={`animate__animated ${animation}`}
          src={logo}
          alt="Logo Mô Sagrado"
        />
        <SubHeader className={`animate__animated ${DEFAULT_ANIMATION}`}>
          <Instagram />
          <AppVersion />
        </SubHeader>
      </LogoContainer>
      <PromoContainer>
      </PromoContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 1rem;
  background: ${(props) => props.theme.colors.light};

  @media (min-width: 821px) {
    flex-direction: column;
    min-height: 100vh;
    width: 15vw;
    position: fixed;
  }
`;

const Logo = styled.img`
  width: 100%;
  max-width: 300px;
  display: block;
  padding: 2rem;
`;

const SubHeader = styled.div`
  display: none;
  text-align: center;

  @media (min-width: 821px) {
    display: block;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const PromoContainer = styled.div`
  display: none;
  @media screen and (min-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 2rem;
    width: 60%;
    background: ${(props) => props.theme.colors.main};
    border-radius: 1rem;
  }
`;
