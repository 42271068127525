import * as React from 'react';
import io from 'socket.io-client';
import styled from 'styled-components';
import vuvuzelaSound from './vuvuzela.mp3';


const matches = [
  {start: '2022-11-27T11:00:10', end: '2022-11-27T15:50:10'},
  {start: '2022-12-02T14:00:10', end: '2022-12-02T17:50:10'},
]

const socket = io('https://vuvuzela.mosagrado.com.br:3030/', { transports : ['websocket'], secure: true });
export default function Vuvuzela() {
  const [isVuvuzelaEnabled, setIsVuvuzelaEnabled] = React.useState(false);
  const [isHost, setIsHost] = React.useState(false);
  const [isHostReady, setIsHostReady] = React.useState(false);

  React.useEffect(() => {
    socket.on('connect', () => {
      setIsVuvuzelaEnabled(isMobile() && isOnMatch());

      if (window.location.search.match(/vuvuzela/)) {
        socket.emit('host');
        socket.on('play', playVuvuzela);
        setIsVuvuzelaEnabled(false);
        setIsHost(true);
      }
    });

    return () => {
      socket.disconnect();
    };

  }, []);


  if ((!isVuvuzelaEnabled && !isHost) || (isHost && isHostReady)) {
    return null;
  }


  function handleClick(event: any) {
    event.preventDefault();

    socket.emit('play');
  }

  if (isHost && !isHostReady) {
    return (
      <HostReady onClick={() => setIsHostReady(true)}/>
    );
  }

  return (
    <VuvuzelaContainer>
      <VuvuzelaButton onClick={handleClick}>Vuvuzela</VuvuzelaButton>
    </VuvuzelaContainer>
  );
}


function HostReady({ onClick }: { onClick: any }) {
  return (
    <HostContainer>
      <button onClick={onClick}>ready</button>
    </HostContainer>
  );
}



function isMobile(): boolean {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

function isOnMatch(): boolean {
  const now = Date.now();
  return !!window.location.search.match(/fakeTime/) ||
    matches.some(({ start, end }) => {
      return now >= Date.parse(start) && now <= Date.parse(end);
    });
}

function playVuvuzela() {
  const audio = new Audio(vuvuzelaSound);
  audio.play();
}

const HostContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 200px;
    height: 200px;
  }
`;


const VuvuzelaContainer = styled.div`
  position: absolute;
  bottom: 100px;
  width: 100%;
  text-align: center;
`;

const VuvuzelaButton = styled.button`
  display: box;
  position: relative;
  width: 100px;
  height: 100px;
  background: linear-gradient(to bottom, yellow 0%, green 100%);
  box-shadow: 0 4px 4px rgba(255, 255, 255, .3);
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: bold;
`;
