import styled from "styled-components";
import { HalfPint } from "./icons/HalfPint";
import { Liter } from "./icons/Liter";
import { Pint } from "./icons/Pint";

import * as Sale from "../../sale";

interface PricesProps {
  beer: any;
}

const getPrice = (beer: any, type: string): string | null => {
  const price = Sale.getPrice(beer, type);
  return formatPrice(price);
};

function formatPrice(price: string): string | null {
  if (price?.toString().trim() !== '-') {
    return `R$${price}`;
  }

  return null;
}

export const Prices: React.FC<PricesProps> = ({ beer }) => {
  const halfPrice = getPrice(beer, 'halfPint');
  const pintPrice = getPrice(beer, 'pint');
  const literPrice = getPrice(beer, 'liter');

  return (
    <Wrapper>
      <PriceContainer>
        {halfPrice && (
          <>
            <Info>
              <Price beer={beer} type="halfPint" />
              <Quantity>350ml</Quantity>
            </Info>
            <Icon width="4em">
              <HalfPint />
            </Icon>
          </>
        )}
      </PriceContainer>
      <PriceContainer>
        {pintPrice && (
          <>
            <Info>
              <Price beer={beer} type="pint" />
              <Quantity>473ml</Quantity>
            </Info>
            <Icon width="4.5em">
              <Pint />
            </Icon>
          </>
        )}
      </PriceContainer>
      <PriceContainer>
        {literPrice && (
          <>
            <Info>
              <Price beer={beer} type="liter" />
              <Quantity>1L</Quantity>
            </Info>
            <Icon width="6em">
              <Liter />
            </Icon>
          </>
        )}
      </PriceContainer>
    </Wrapper>
  );
};


function Price({ beer, type }: { beer: any, type: string }) {
  const price = getPrice(beer, type);

  return (
    <PriceStyle>
      {price}
      {Sale.isOnSaleTime(beer.sale, type) && (<OldPrice>{formatPrice(beer[type])}</OldPrice>)}
    </PriceStyle>
  )
}




const Wrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: 2rem;

  @media (min-width: 821px) {
    margin-top: 0;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2rem;
  font-size: 1rem;
  line-height: 1;

  @media (min-width: 821px) {
    min-width: 114px;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: 821px) {
    margin-right: 1rem;
  }
`;

const PriceStyle = styled.span`
  font-size: ${(props) => props.theme.fontSize.pint};
  font-weight: ${(props) => props.theme.fontWeight.extrabold};
`;

const OldPrice = styled.span`
  display: block;
  font-size: 1.8rem;
  text-decoration: line-through;
  width: 100%;
  opacity: 0.7;
  text-align: right;
`;

const Quantity = styled.span`
  margin-top: 0.2rem;
  font-size: ${(props) => props.theme.fontSize.shot};
  font-weight: ${(props) => props.theme.fontWeight.semibold};
`;

const Icon = styled.div<{ width: string }>`
  display: flex;
  font-size: 5px;
  @media (min-width: 821px) {
    font-size: 10px;
  }

  svg {
    width: 30px;
    max-height: ${(props) => props.width};
  }
`;
