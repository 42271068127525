import { useEffect, useState } from "react";
import ms from "ms";
import styled from "styled-components";
import { BeerDetails } from "../BeerDetails";
import { Prices } from "../Prices";
import { Beer } from "./types";
import { useBeers } from "./utils";

import * as SaleLib from "../../sale";

const DEFAULT_SALE_ANIMATION = "animate__heartBeat";

export const Taplist = () => {
  const beers: Array<Beer> | undefined = useBeers();
  const [saleAnimation, setSaleAnimation] = useState<string>("");

  useEffect(() => {
    const delay = ms("5s");

    const intervalId = setInterval(() => {
      const animation = saleAnimation !== "" ? "" : DEFAULT_SALE_ANIMATION;
      setSaleAnimation(animation);
    }, delay);

    return () => clearInterval(intervalId);
  }, [saleAnimation]);

  if (!beers || beers.length <= 0) {
    return (
      <Wrapper>
        <h1>Carregando...</h1>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <List>
        {beers.map((beer) => {
          return (
            <ListItem
              key={`tap-id-${beer.tap}`}
              disabled={beer.inService === "no"}
            >
              <TapNumber>{beer.tap}</TapNumber>
              <Container>
                <InnerContent>
                  <BeerDetails beer={beer} />
                  <HalfContainer>
                    <Brewery>
                      <span>
                        Cervejaria <b>{beer.brand}</b>
                      </span>
                    </Brewery>
                    <Sale>
                      <span className={`animate__animated ${saleAnimation}`}>
                        {getSale(beer.sale)}
                      </span>
                    </Sale>
                  </HalfContainer>
                </InnerContent>
                <Prices beer={beer}/>
              </Container>
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};


function getSale(sale: string): null|string {
  const saleInfo = sale.split(';');
  if (saleInfo.length === 6 && SaleLib.isOnSaleTime(sale)) {
    return saleInfo[0];
  }

  if (saleInfo.length < 6) {
    return sale;
  }

  return null;
}

const Wrapper = styled.div`
  width: 100%;

  @media (min-width: 821px) {
    width: 85vw;
    margin-left: 15vw;
    max-height: 100vh;
  }
`;

const List = styled.ul`
  width: 100%;
  list-style: none;
`;

const ListItem = styled.li<{ disabled: boolean }>`
  display: flex;
  width: 100%;
  border-bottom: 0.3rem solid ${(props) => props.theme.colors.highlight};
  opacity: ${(props) => (props.disabled ? "0.2" : "1")};
  color: ${(props) => props.theme.colors.light};

  @media (min-width: 821px) {
    height: calc(100vh / 10);
    padding-right: 10px;
  }
`;

const TapNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.3rem;
  margin-right: 2rem;
  font-size: ${(props) => props.theme.fontSize.halfPint};
  font-weight: ${(props) => props.theme.fontWeight.extrabold};
  background-color: ${(props) => props.theme.colors.highlight};

  @media (min-width: 821px) {
    width: 8.5rem;
    font-size: ${(props) => props.theme.fontSize.growler};
  }
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 2rem 0;

  @media (min-width: 821px) {
    flex-direction: row;
    padding: 0;
  }
`;

const InnerContent = styled.div`
  display: block;

  @media (min-width: 821px) {
    display: flex;
    flex: 1;
    align-items: center;
  }
`;

const HalfContainer = styled.div`
  @media (min-width: 821px) {
    width: 50%;
    display: flex;
    align-items: center;
  }
`;

const Brewery = styled.div`
  font-size: ${(props) => props.theme.fontSize.shot};
  font-weight: ${(props) => props.theme.fontWeight.semibold};
  text-transform: uppercase;

  @media (min-width: 821px) {
    display: flex;
    flex-direction: column;
  }

  b {
    font-weight: ${(props) => props.theme.fontWeight.semibold};

    @media (min-width: 821px) {
      display: block;
      font-size: ${(props) => props.theme.fontSize.halfPint};
      font-weight: ${(props) => props.theme.fontWeight.extrabold};
    }
  }
`;

const Sale = styled.div`
  display: none;
  font-size: ${(props) => props.theme.fontSize.halfPint};
  font-weight: ${(props) => props.theme.fontWeight.extrabold};
  text-transform: uppercase;

  @media (min-width: 821px) {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
  }
`;
