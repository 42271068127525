import styled from "styled-components";

export const Instagram = () => {
  return (
    <Wrapper>
      <span>@mosagrado</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;

  span {
    font-size: ${(props) => props.theme.fontSize.halfPint};
    font-weight: ${(props) => props.theme.fontWeight.extrabold};
    color: ${(props) => props.theme.colors.main};
  }
`;
