const GROWLER_DAY = 10; // ignore growler day I gonna keep the code to rollback after summer time
const GROWLER_DAY_DISCOUNT = 0.3;


export function isOnSaleTime(sale: string, type?: string): boolean {
  if (isGrowlerDay() && type === 'liter') {
    return true;
  }

  const saleInfo = sale.split(";").filter(v => v.trim().length > 0);

  if (saleInfo.length === 0 || saleInfo.length < 6) {
    return false;
  }

  const [startAtHour, startAtMinute] = saleInfo[1]?.split(":");
  const [endAtHour, endAtMinute] = saleInfo[2]?.split(":");

  if (!startAtHour || !endAtHour) {
    return false;
  }

  const now = new Date();
  const startAtTime = new Date();
  startAtTime.setHours(parseInt(startAtHour, 10));
  startAtTime.setMinutes(parseInt(startAtMinute, 10));
  const endAtTime = new Date();
  endAtTime.setHours(parseInt(endAtHour, 10));
  endAtTime.setMinutes(parseInt(endAtMinute, 10));

  return now > startAtTime && now < endAtTime;
}


export function getPrice(beer: any, type: string): string {
  if (isGrowlerDay() && type === 'liter') {
    const price = parseInt(beer.liter, 10);
    if (isNaN(price)) {
      return '-';
    }

    return Math.ceil(beer.liter - parseInt(beer.liter, 10) * GROWLER_DAY_DISCOUNT).toString();
  }

  if (!isOnSaleTime(beer.sale)) {
    return beer[type];
  }

  const [halfPint, pint, liter] = beer.sale.split(';').slice(3);
  return { halfPint, pint, liter }[type];
}

export function isGrowlerDay() {
  const today = new Date();
  return today.getDay() === GROWLER_DAY;
}
