import styled from "styled-components";

interface BeerDetailsProps {
  beer: any;
}

export const BeerDetails: React.FC<BeerDetailsProps> = ({ beer }) => {
  return (
    <Details>
      <BeerStyle>{beer.style}</BeerStyle>
      <Info>
        <BeerName>{beer.name}</BeerName>
        <span>{`${beer.abv}% ABV`}</span>
      </Info>
    </Details>
  );
};

const Details = styled.div`
  line-height: 1;

  @media (min-width: 821px) {
    width: 50%;
  }
`;

const BeerStyle = styled.span`
  display: block;
  margin-bottom: 0.5rem;
  font-size: ${(props) => props.theme.fontSize.pint};
  font-weight: ${(props) => props.theme.fontWeight.extrabold};
  text-transform: uppercase;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1rem;

  @media (min-width: 821px) {
    margin-bottom: 0rem;
  }

  span {
    margin-right: 1rem;
    font-size: ${(props) => props.theme.fontSize.shot};
    font-weight: ${(props) => props.theme.fontWeight.semibold};
    color: #a8cfff;
  }
`;

const BeerName = styled.div`
  margin-right: 2rem;
  font-size: ${(props) => props.theme.fontSize.halfPint};
  font-weight: ${(props) => props.theme.fontWeight.extrabold};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.highlight};
`;
