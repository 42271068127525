import styled from "styled-components";

export const Layout = styled.div`
  min-height: 100vh;

  border: 5px solid ${(props) => props.theme.colors.highlight};
  background-color: ${(props) => props.theme.colors.main};

  // width: 100%;

  @media (min-width: 821px) {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    border: none;
  }
`;
