import { useEffect, useState } from "react";
import ms from "ms";
import axios from "axios";
import csv from "csvtojson";

import { Beer } from "./types";

export function useBeers(): Array<Beer> | undefined {
  const [beers, setBeers] = useState<Array<Beer> | undefined>();

  useEffect(() => {
    pollBeers(setBeers);
  }, []);

  return beers;
}

let interval: number;
export async function pollBeers(callback: (beers: Array<Beer>) => void) {
  if (interval) return;

  callback(await getBeers());

  interval = window.setInterval(async () => {
    callback(await getBeers());
  }, ms("20sec"));
}

async function getBeers(): Promise<Array<Beer>> {
  const { data } = await axios.get(
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vTExUZQH32G1uM0ngOM5_5plvobWcdhYMZo5WlZTnenJUxMgh-aIihggKTtNyVLW-7t8LjkdoaJyfOM/pub?output=csv&cache=" +
      Date.now().toString()
  );
  const beers = await parseData(data);

  return beers.filter(onlyReady).filter(onlyLastOnTapList).sort(byTap);
}

function onlyReady({ ready }: { ready: string }) {
  return ready === "yes";
}

function onlyLastOnTapList(
  { tap }: { tap: number },
  index: number,
  tapList: Array<Beer>
): boolean {
  return !tapList
    .slice(index + 1, tapList.length)
    .some((t: Beer) => t.tap === tap);
}

function byTap({ tap: tapA }: { tap: number }, { tap: tapB }: { tap: number }) {
  return tapA - tapB;
}

async function parseData(body: string): Promise<Array<Beer>> {
  const data = await csv().fromString(body);
  return data;
}
