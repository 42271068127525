import styled from "styled-components";
import pjson from "../../../package.json";

export const AppVersion = () => {
  return <Version>beta {pjson.version}</Version>;
};

const Version = styled.p`
  font-size: ${(props) => props.theme.fontSize.shot};
  color: #a3a9ae;
`;
