export const HalfPint = () => {
  return (
    <svg
      width="40"
      height="62"
      viewBox="0 0 40 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.60794 2.02982C9.75936 1.37076 14.4827 0.858398 21.3048 0.858398C28.1197 0.858398 32.5439 1.36946 35.3235 2.03817C36.7093 2.37156 37.7397 2.75654 38.4666 3.17188C38.8305 3.37983 39.1536 3.61529 39.4091 3.88592C39.658 4.1496 39.9044 4.51337 39.981 4.973C40.0006 5.09079 40.0036 5.21076 39.9897 5.32937L34.0129 56.5774C33.9871 56.7982 33.904 57.0084 33.7716 57.1871C33.1506 58.0255 31.8252 59.0002 29.8353 59.7634C27.807 60.5413 25.0009 61.1353 21.3048 61.1353C13.9419 61.1353 8.44131 58.7772 6.48994 57.4922C6.17066 57.2819 5.96291 56.9392 5.92421 56.5588L0.710392 5.31077C0.676896 4.98153 0.773068 4.6522 0.978482 4.39273C1.38784 3.87564 2.10318 3.45603 2.93929 3.10399C3.8279 2.72983 5.03324 2.35915 6.60794 2.02982ZM3.31098 5.7445L8.39021 55.6697C10.3407 56.7576 15.051 58.592 21.3048 58.592C24.7301 58.592 27.2225 58.0415 28.9245 57.3887C30.3365 56.8472 31.1535 56.2566 31.5326 55.8895L37.4083 5.50804C37.356 5.47133 37.2891 5.42832 37.2047 5.38011C36.7632 5.12783 35.9858 4.81339 34.7286 4.51095C32.2223 3.90799 28.0205 3.40173 21.3048 3.40173C14.5965 3.40173 10.0578 3.90669 7.12857 4.5193C5.66293 4.82581 4.62325 5.15453 3.92625 5.44801C3.65627 5.56168 3.45618 5.66247 3.31098 5.7445ZM37.5693 5.64289C37.5692 5.64289 37.568 5.64144 37.5657 5.6385C37.5683 5.64142 37.5694 5.64289 37.5693 5.64289Z"
        fill="#FD8000"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.0782 49.3907C34.4543 49.9838 34.2784 50.7696 33.6853 51.1457C31.7586 52.3675 26.4464 54.6497 19.779 54.6497C13.1086 54.6497 8.19257 52.3648 6.44493 51.1027C5.87558 50.6915 5.74737 49.8966 6.15857 49.3272C6.56977 48.7578 7.36467 48.6296 7.93403 49.0408C9.23839 49.9829 13.6309 52.1064 19.779 52.1064C25.9299 52.1064 30.774 49.9803 32.3232 48.9978C32.9163 48.6217 33.7021 48.7976 34.0782 49.3907Z"
        fill="#FD8000"
      />
    </svg>
  );
};
